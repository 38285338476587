<template>
  <div class="row" style="margin-top:5%;">
    <div class="col-12" style="text-align:center;">
      <h2 style="color:#f39c12;">MIS OFERTAS</h2>
    </div>
  </div>
  <div class="row" style="margin-top:5%;">
    <div class="col-lg-3"> </div>     
    <div class="col-lg-6 col-md-12">   
      <el-card class="box-card">
          <template #header>
            <div class="card-header">
              <span >MIS OFERTAS</span>
              <el-button class="button" text></el-button>
            </div>
          </template>
          <el-collapse v-model="activeNames" @change="handleChange">
      <el-collapse-item title="Consistency" name="1">
        <el-timeline>
            <el-timeline-item
              v-for="(activity, index) in activities"
              :key="index"
              :icon="activity.icon"
              :type="activity.type"
              :color="activity.color"
              :size="activity.size"
              :hollow="activity.hollow"
              :timestamp="activity.timestamp"
            >
              {{ activity.content }}
            </el-timeline-item>
          </el-timeline>
      </el-collapse-item>
      <el-collapse-item title="Feedback" name="2">
        <el-timeline>
            <el-timeline-item
              v-for="(activity, index) in activities"
              :key="index"
              :icon="activity.icon"
              :type="activity.type"
              :color="activity.color"
              :size="activity.size"
              :hollow="activity.hollow"
              :timestamp="activity.timestamp"
            >
              {{ activity.content }}
            </el-timeline-item>
          </el-timeline>
      </el-collapse-item>
      <el-collapse-item title="Efficiency" name="3">
        <el-timeline>
            <el-timeline-item
              v-for="(activity, index) in activities"
              :key="index"
              :icon="activity.icon"
              :type="activity.type"
              :color="activity.color"
              :size="activity.size"
              :hollow="activity.hollow"
              :timestamp="activity.timestamp"
            >
              {{ activity.content }}
            </el-timeline-item>
          </el-timeline>
      </el-collapse-item>
      <el-collapse-item title="Controllability" name="4">
        <el-timeline>
            <el-timeline-item
              v-for="(activity, index) in activities"
              :key="index"
              :icon="activity.icon"
              :type="activity.type"
              :color="activity.color"
              :size="activity.size"
              :hollow="activity.hollow"
              :timestamp="activity.timestamp"
            >
              {{ activity.content }}
            </el-timeline-item>
          </el-timeline>
      </el-collapse-item>
    </el-collapse>         
      </el-card>
    </div>    
    <div class="col-lg-3"></div>         
  </div>
</template>

<script>
   import { MoreFilled } from '@element-plus/icons-vue'
  import { useRouter } from 'vue-router'
  export default {   
    setup() {
      const activities = [
              {
                content: 'Custom icon',
                timestamp: '2018-04-12 20:46',
                size: 'large',
                type: 'primary',
                icon: MoreFilled,
              },
              {
                content: 'Custom color',
                timestamp: '2018-04-03 20:46',
                color: '#0bbd87',
              },
              {
                content: 'Custom size',
                timestamp: '2018-04-03 20:46',
                size: 'large',
              },
              {
                content: 'Custom hollow',
                timestamp: '2018-04-03 20:46',
                type: 'primary',
                hollow: true,
              },
              {
                content: 'Default node',
                timestamp: '2018-04-03 20:46',
              },
            ]
      return{
        activities
      }
      
    }
      
     
  }
  </script>
  
  <style>
  
  </style>