<template>
  <div class="container-fluid">
    <div class="h3 font-weight-bold title">{{candidate.firstName}} {{candidate.lastName}}</div>        
  </div>
  <div class="clearfix"><hr/></div>
  <div class="row"><h4>Curriculum Vitae</h4></div>
  <div class="row">
  </div>
  <div class="row">
      <div class="col-lg-5 col-md-12">
        <div class="container-fluid mt-sm-4 mb-5">
            <el-card shadow="never">   
                <div class="h4 text-center font-weight-bold title">Datos de Acceso</div>
                <br/>
                <div class="row">
                    <div class="col-md-4" ><el-image style="width: 200px; height: 200px" :src="url" :fit="fit" /></div>
                    <div class="col-md-8">   <br/>   <br/> <label>Usuario</label> <input type="text" placeholder="Nombre" v-model="candidate.email" clearable> </div>                        
                </div>
                                                          
            </el-card>
        </div>
        <el-card shadow="never">                 
                  <div class="h4 text-center font-weight-bold title">Datos Personales</div>
                  <div class="row my-4">
                      <div class="col-md-6"> <label>Nombre</label> 
                        
                        <input type="text" placeholder="Nombre" v-model="candidate.firstName" clearable> 
                      </div>
                      <div class="col-md-6 pt-md-0 pt-4"> <label>Apellido</label> <input type="text" placeholder="Apellido" v-model="candidate.lastName" clearable> </div>
                  </div>
                  <div class="row my-md-4 my-2">
                      <div class="col-md-6"> <label>Correo electronico</label> <input type="email" placeholder="Correo electronico" v-model="candidate.email" clearable> </div>
                      <div class="col-md-6 pt-md-0 pt-4"> <label>Telefono</label> <input type="tel" placeholder="Telefono" v-model="candidate.phone" clearable> </div>
                  </div>
                  <div class="row my-md-4 my-2">
                      <div class="col-md-6"> <label>Fecha de Nacimiento</label> <input type="text" placeholder="DD" style="width:15%;" v-model="candidate.birthDate.day">| <input type="text" placeholder="MM" style="width:15%;" v-model="candidate.birthDate.month">|<input type="text" placeholder="YYYY" style="width:25%;" v-model="candidate.birthDate.year" >  </div>
                        <div class="col-md-6 pt-md-0 pt-4">
                          <label class="mt-md-0 mt-2">Sexo</label>
                              <div class="d-lg-flex justify-content-between">
                                      <div class="size"> <label class="option"> <input type="radio" name="radio" v-model="candidate.gender" value="1">Hombre <span class="checkmark" ></span> </label> </div>
                                      <div class="size"> <label class="option"> <input type="radio" name="radio" v-model="candidate.gender" value="0">Mujer <span class="checkmark" ></span> </label> </div>                          
                              </div>                         
                       </div>                    
                  </div>        
                  <div class="row my-md-4 my-2">
                      <div class="col-md-2 pt-md-0 pt-4"> <label>Código Postal</label> <input type="text" placeholder="Código Postal" v-model="candidate.postalCode" > </div>                       
                      <div class="col-md-5"> <label>Localidad</label> <input type="text" placeholder="Localidad" v-model="candidate.locality" > </div>
                      <div class="col-md-5 pt-md-0 pt-4"> <label>Provincia</label>   <option v-for="prov in provinces" :value="prov.name" :key="prov.id">{{prov.name}}</option>
                      </div>                    
                  </div>                
                  <div class="row my-md-4 my-2"> </div>                        
              
          </el-card >
      </div>
      <div class="col-lg-7 col-md-12">
        <div class="row">
            <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick" >
                <el-tab-pane label="Experiencia" name="laboral">
                    <div class="col-12" style="height:800px;overflow-y:auto;overflow-x:none;">
                        <div class="row">
                            <div class="col-lg-6 col-md-12"></div>
                            <div class="col-lg-6 col-md-12"><el-button type="primary" plain @click="laboralVisible = true">Añadir Experiencia</el-button></div>                           
                        </div>
                        <div class="row">
                            <div class="col-12"><hr/></div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <LaboralExperience/>
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="Estudios" name="estudies">
                    <div class="col-12" style="height:800px;overflow-y:auto;overflow-x:none;">
                        <div class="row">
                            <div class="col-lg-6 col-md-12"></div>
                            <div class="col-lg-6 col-md-12"> <el-button type="primary" plain  @click="estudiesVisible = true">Añadir Estudios</el-button></div>                           
                        </div>
                        <div class="row">
                            <div class="col-12"><hr/></div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <EducationExperience/>
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="Idiomas" name="languaje">
                    <div class="col-12" style="height:800px;overflow-y:auto;overflow-x:none;">
                        <div class="row">
                            <div class="col-lg-6 col-md-12"></div>
                            <div class="col-lg-6 col-md-12">    <el-button type="primary" plain  @click="languajeVisible = true">Añadir Idiomas</el-button></div>                           
                        </div>
                        <div class="row">
                            <div class="col-12"><hr/></div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <LanguageExperience/>
                            </div>
                        </div>

                    </div>
                </el-tab-pane>
                
              </el-tabs>
        </div>
      </div>   
  </div>
  <div class="row">
      <div class="col-lg-12 col-md-12">
          <el-dialog v-model="laboralVisible" >
              <template  #header >
              <div class="my-header">
                  <h2  class="title" id="exampleModalLabel">Añadir Experiencia</h2>       
              </div>
              </template>
              
              <div class="col-lg-12 col-md-12">
                  <AddLaboralExperience/>                
              </div>
                                    
  
          </el-dialog>        
      </div>
  </div>
  
  <div class="row">
      <div class="col-lg-6 col-md-12">
          <el-dialog v-model="languajeVisible" >
              <template  #header >
              <div class="my-header">
                  <h2  class="title" id="exampleModalLabel">Añadir Idiomas</h2>       
              </div>
              </template>
              <div class="col-lg-3 col-md-12">
                  <br/>
              </div>
              <div class="col-lg-6 col-md-12">
                  <AddLanguageExperience/>                
              </div>
              <div class="col-lg-3 col-md-12">
                  <br/>
              </div>                        
  
          </el-dialog>        
      </div>
  </div>
  
  <div class="row">
      <div class="col-lg-6 col-md-12">
          <el-dialog v-model="estudiesVisible" >
              <template  #header >
              <div class="my-header">
                  <h2  class="title" id="exampleModalLabel">Añadir Educación</h2>       
              </div>
              </template>
              <div class="col-lg-3 col-md-12">
                  <br/>
              </div>
              <div class="col-lg-6 col-md-12">
                  <AddEducationExperience/>                
              </div>
              <div class="col-lg-3 col-md-12">
                  <br/>
              </div>                        
  
          </el-dialog>        
      </div>
  </div> 
  <div class="row" style="margin-top:25px;">
    <div class="col-lg-3 col-md-12"></div>
    <div class="col-lg-4 col-md-12">

        <div class="d-flex justify-content-center" v-if="candidate.id==0"> 
            <button class="btn" @click="register" >Confirmar Registro</button> 
        </div>
        <div class="d-flex justify-content-center" v-if="candidate.id>0"> 
            <button class="btn" @click="register" >Actualizar Registro</button> 
        </div>        
    </div>
    <div class="col-lg-4 col-md-12"></div>    
  </div>
  <div class="row">
    <br/>
  </div>
  
  
  </template>
  
  <script>
  import {computed,ref} from 'vue'
  import { useRouter } from 'vue-router'
  import {useStore} from 'vuex'
  import useCandidate from '@/modules/candidates/composables/useCandidate'
  import useMasters from '@/modules/general/composables/useMasters'
  import LaboralExperience from "@/modules/candidates/components/LaboralExperience.vue"
  import EducationExperience from "@/modules/candidates/components/EducationExperience.vue"
  import LanguageExperience from "@/modules/candidates/components/LanguageExperience.vue"
  import AddLaboralExperience from "@/modules/candidates/components/AddLaboralExperience.vue"
  import AddEducationExperience from "@/modules/candidates/components/AddEducationExperience.vue"
  import AddLanguageExperience from "@/modules/candidates/components/AddLanguagesExperience.vue"
  export default {
   components:{
       LaboralExperience,EducationExperience,AddLaboralExperience,AddEducationExperience,AddLanguageExperience,LanguageExperience
   },
  
   setup(){
           const store =useStore()
           const {register}=useCandidate()     
           const {getProvinces}=useMasters()
           const candidate = computed(() => store.state.candidate.candidate)
           const laboralVisible=ref(false)
           const estudiesVisible=ref(false)
           const languajeVisible=ref(false)
           const router = useRouter()
           const activename=ref('laboral')
           const url =ref('https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg')
           getProvinces()
    
  
          return {
              candidate,
              register,
              getProvinces,
              laboralVisible,
              estudiesVisible,
              languajeVisible,
              url,activename
          }
   },
  }
  </script>
  
  <style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&family=Roboto&display=swap');
  
  .demo-tabs > .el-tabs__content {
    padding: 32px;
    color: #6b778c;
    font-size: 32px;
    font-weight: 600;
  }
  .search {
      background-color: #fdfbfc
  }
  .title{
    color:#0476e0;
  }
  .search input {
      width: 100px;
      border: none;
      outline: none;
      padding-top: 2px;
      padding-bottom: 2px;
      background-color: #fdfbfc
  }
  
  div.btn,
  button.btn {
      background-color: #F07F29;
      color: #eee
  }
  
  div.btn:hover,
  button.btn:hover {
      background-color: #ac1f32d7
  }
  
  .navbar-light .navbar-nav .nav-link {
      color: #333
  }
  
  nav {
      float: left
  }
  
  #language {
      float: right
  }
  
  #language select {
      border: none;
      outline: none
  }
  
  .wrapper {
      width: 85%;
      margin: 20px auto;
      box-shadow: 1px 1px 30px 10px rgba(250, 250, 250, 0.8)
  }
  
  .h3 {
      padding-top: 40px;
      font-size: 34px
  }
  
  .h4 {
      padding-top: 40px;
      font-size: 24px
  }

  label {
      display: block;
      font-size: 0.8rem;
      font-weight: 700
  }
  
  input {
      border: none;
      outline: none;
      border-bottom: 2px solid #ddd;
      width: 100%;
      padding-bottom: 10px
  }
  
  .wrapper {
      clear: both
  }
  
  #province {
      border: none;
      outline: none;
      width: 100%;
      padding-bottom: 12px;
      border-bottom: 2px solid #ddd
  }
  
  .wrapper .col-md-6:hover label {
      color: #F07F29
  }
  
  .wrapper .col-md-6:hover input,
  .wrapper .col-md-6:hover #country {
      border-color: #F07F29;
      cursor: pointer
  }
  
  .wrapper .col-md-6 input:focus {
      border-color: #F07F29
  }
  
  .option {
      position: relative;
      padding-left: 30px;
      display: block;
      cursor: pointer;
      color: #888
  }
  
  .option input {
      display: none
  }
  
  .checkmark {
      position: absolute;
      top: -1px;
      left: 0;
      height: 20px;
      width: 20px;
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 50%
  }
  
  .option input:checked~.checkmark:after {
      display: block
  }
  
  .option .checkmark:after {
      content: "\2713";
      width: 10px;
      height: 10px;
      display: block;
      position: absolute;
      top: 30%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: 200ms ease-in-out 0s
  }
  
  .option:hover input[type="radio"]~.checkmark {
      background-color: #f4f4f4
  }
  
  .option input[type="radio"]:checked~.checkmark {
      background: #F07F29;
      color: #fff;
      transition: 300ms ease-in-out 0s
  }
  
  .option input[type="radio"]:checked~.checkmark:after {
      transform: translate(-50%, -50%) scale(1);
      color: #fff
  }
  
  
  </style>