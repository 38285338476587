<template>
	<div class="row" >
		<div class="pic-block col-lg-5 col-md-12 col-sm-9 col-10" style="margin-top:10%;">
			<img src="@/assets/images/undraw_login_re_4vu2.svg" class="img-fluid" style="width:80%;height:80%;">
		</div>
		<div class="login-block col-lg-6 col-md-12 col-sm-12 col-12" style="margin-top:7%;">
			<div class="row">
				<div class="col-lg-12 col-md-12">
				 	<h2 class="mb-3" style="color:#0476e0">ACCESO CANDIDATOS</h2>
				</div>			 
			</div>
			<div class="col-12">
				<div class="form-group looking" style="width:85%;">				 
					<input type="text" placeholder="Usuario" v-model="auth.userName" required class="form-control string email optional form-control form-control-solid h-auto py-7 px-6 rounded-lg">				 
				</div>
				<div  class="form-group looking" style="width:85%;">					
					<input type="password" placeholder="Contraseña" v-model="auth.password" required class="form-control string email optional form-control form-control-solid h-auto py-7 px-6 rounded-lg">
				</div>
				<div class="row">
					<div class="col-lg-5 col-md-5 col-sm-12"> 
					 
						<el-button type="primary" plain  @click="register" style="min-width:200px;margin-bottom:5px;">Recordar Contraseña</el-button>
					</div>
					<div class="col-lg-3 col-md-2"> </div>
					<div class="col-lg-4 col-md-5 col-sm-12"> 
						 
						<el-button type="primary" plain   @click="authentication" style="min-width:200px;margin-bottom:5px;">Entrar</el-button>
					</div>
					<br/><br/>
					<hr/>
					<br/>
					<div class="col-lg-12 col-md-12"> <strong><span>Quieres registrarte? </span></strong><a class="labelRegistro" @click="goToRegister">ir a Registro</a></div>
				</div>
				<div class="row" v-show="logued">
					<div class="col-12">
						<el-alert :title="msgUser" type="error" show-icon />    						
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { ref} from 'vue'
import { useRouter } from 'vue-router'
import useSecurity from '@/modules/security/composables/useSecurity'
export default {
  setup() {
		const {auth,authentication,msgUser,logued} =useSecurity()     
      const router = useRouter()
      const goToRegister= () =>{router.push({name: 'register'})}
      const user=ref({user:'',password:''})
 
    return {
        goToRegister
		,user
		,auth
		,authentication
	/*	,onSubmit:async()=>{  
			console.log("entrando al logueo")
			const resp=await authentication
		}*/
		,msgUser
		,logued
    }
},
}
</script>

<style scoped>
.labelRegistro{
	color: #F07F29;
}
label {
    display: block;
    font-size: 0.8rem;
    font-weight: 700
}

input {
    border: none;
    outline: none;
    border-bottom: 2px solid #ddd;
    width: 100%;
    padding-bottom: 10px
}

div.btn,
button.btn {
    background-color: #F07F29;
    color: #eee
}

div.btn:hover,
button.btn:hover {
    background-color: #fff;
	color:#F07F29;
	border-color: #F07F29;
}

.hq-template{
	margin-top: 30px;
	margin-bottom: 30px;
	background-image: url('/assets/images/login7-bg.svg');
	background-repeat: no-repeat;
	background-size: cover;
	box-shadow: 0px 0px 20px 0px rgba(0,0,0,.2);
	background-position: center;
}
.pic-block img{
	width: 100%;
	height: 100%;
}

.login-block{
	margin: auto;
	padding: 5%;
}
.login-block h2 {
	text-align: center;
	font-weight: 600;
	color: #F07F29;
	text-transform: uppercase;
}
.login-block input{
	box-shadow: none !important;
	outline: none !important;
	border-radius: 10px;
	color: #111;
	transition: 0.6s;
	padding-left: 30px;
}
.login-block input:focus{
	border-color: #F07F29!important;
}
.login-block input::placeholder{
	color: #111;
}
.login-block svg{
    margin-bottom: -70px;
    margin-left: 10px;
}
.login-block .forgot{
	color: #111;
	float: right;
	font-weight: 600;
}
.login-block .login-btn{
	margin-top: 70px;
	text-align: center;
}
.login-block .login-btn a{
	text-decoration: none;
	padding: 10px 30px;
	background: #F07F29;
	border-radius: 10px;
	color: #383838;
	font-weight: 600;
	text-transform: uppercase;
}
.login-block p{
	color: #383838;
	margin-top: 20px;
	font-weight: 600;
	text-align: center;
}
.login-block p a {
	color: #383838;
	text-transform: uppercase;
	font-weight: 600;
}
.copyright{
  text-align: center; 
    padding: 20px;
    font-size: 16px;
}
.copyright a{
  color: #F07F29 !important;
  font-weight: bold;
}
/*************************** media queries *************/
@media only screen and (max-width: 750px){
	.pic-block{
		margin: auto;
		margin-top: 20px;
	}
}
</style>